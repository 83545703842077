/**
 * Portfolio component
 *
 * Highlights some of  your creations. These can be designs, websites,
 * open source contributions, articles you've written and more.
 *
 * This is a great area for you to to continually add to and refine
 * as you continue to learn and create.
 */

import React from "react";

/**
 * Desk image
 *
 * Below is a sample desk image. Feel free to update this to an image of your choice,
 * updating below imageAltText to string that represents what you see in that image.
 *
 * Need an image? Check out https://unsplash.com to download a photo you
 * freely use on your site.
 */
// import image from "../images/tech.jpg";

const imageAltText = "blue background";

/**
 * Project list
 *
 * An array of objects that will be used to display for your project
 * links section. Below is a sample, update to reflect links you'd like to highlight.
 */
const projectList = [
  {
    title: "HacktoberFest",
    description:
      "Earned final level four badge in hacktober fest by contributing to various open source projects",
    url: "https://www.holopin.io/@jalaj028#",
  },
  {
    title: "Technoforum website for college",
    description:
      "Built technoforum website for BIAS bhimtal",
    url: "https://techno-alpha.vercel.app/",
  },
   {
    title: "Blog",
    description:
      "My Hashnode Profile",
    url: "https://hashnode.com/@Jalaj2004",
  },
  {
    title: "KhelFanz",
    description:
      "Building news website for NewsFanz pvt. ltd.",
    url: "https://khel-fanz-user-4ih1.vercel.app/",
  },
   {
    title: "Techno Admin website",
    description:
      "Admin panel website for technoforum society",
    url: "https://techno-admin-bias.vercel.app/",
  },
   {
    title: "KhelFanz Admin website",
    description:
      "Admin panel website for Khelfanz",
    url: "https://khel-fanz-user-4ih1.vercel.app/",
  },
];

const Portfolio = () => {
  return (
    <section className="padding" id="portfolio">
      <h2 style={{ textAlign: "center" }}>Portfolio</h2>
      <div style={{ display: "flex", justifyContent:"center", padding: "3rem"}}>
        <div style={{display: "flex", flexWrap:"wrap", justifyContent:"space-between"}}>
          {projectList.map((project) => (
            <div style={{boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",margin:"1rem", padding:"1rem"}} key={project.title}>
              <a href={project.url} target="_blank" rel="noopener noreferrer">
                <h3 style={{ flexBasis: "40px" }}>{project.title}</h3>
              </a>
              <p className="small">{project.description}</p>
            </div>
          ))}
          </div>
        </div>
    </section>
  );
};

export default Portfolio;
