/**
 * About component
 *
 * Space for you to describe more about yourself.
 */

import React from "react";

/**
 * About background image
 *
 * Below is a sample image. Upload the image of your choice into the "images"
 * directory and import here for use. Then, set imageAltText to string that 
 * represents what you see in that image.
 *
 * Need an image? Check out https://unsplash.com to download a image you
 * freely use on your site.
 */
// import image from "../images/tech.jpg";
import image from "../images/digital.jpg";


const imageAltText = "tech image";

/**
 * Sort description that expands on your title on the Home component.
 */
const description =
  "I am a B.Tech undergraduate at Birla Institute of Applied Sciences, Bhimtal, deeply passionate about technology and continuous learning. Currently, I am expanding my expertise in the MERN stack, which complements my robust skills in full-stack development. I have actively applied my knowledge to various real-life projects, honing my ability to deliver practical and effective solutions. My proactive approach to learning new technologies underscores my commitment to staying at the forefront of the industry.";


 // * Mernstack | Java | Open Source
 // * I am passionate about contributing to open source repositories and building real world projects
 
const skillsList = [
"Java",
"JavaScript",
"Node.js",
"Reactjs",
"Express",
"Typescript",
"Git & GitHub",
"Nextjs",
"Data Structures",
"MongoDB",
"MySQL",
"PostgreSQL",
"HTML",
"CSS",
"TailWind CSS",
"Bootstrap",
"AWS",
"Jenkins",
"Docker", 
"Devops",
"OOP",
"EJS",
"Jquery",
"Serverless",
"React Native",
"Shopify App Development",
"GraphQl",
"Redux",
"State Management"
];

/**
 * Use this to give more information about what you are passionate about,
 * how you best work, or even a quote. This will help someone learn more
 * about you on a professional level.
 */
const detailOrQuote =
  "I am passionate about solving problems in new creative ways to drive innovation. I try to sharpen my skills by actively contributing to open-source repositories.";

const About = () => {
  return (
    <section className="padding" id="about">
      <img className="background" src={image} alt={imageAltText} />
      <div
        style={{
          backgroundColor: "white",
          width: "50%",
          padding: "4rem",
          margin: "3rem auto",
          textAlign: "center",
          display:"flex",
          justifyContent:"center",
          flexWrap:"wrap"
        }}
      >
        <h2>About Myself</h2>
        <p className="large" style={{margin:"8%"}}>{description}</p>
        <hr />
        <ul
          style={{
            textAlign: "left",
            columns: 2,
            fontSize: "1.25rem",
            margin: "2rem 3rem",
            gap: "3rem",
          }}
        >
          {skillsList.map((skill) => (
            <li key={skill}>{skill}</li>
          ))}
        </ul>
        <hr />
        <p style={{margin:"5%"}}>{detailOrQuote}</p>
      </div>
    </section>
  );
};

export default About;
